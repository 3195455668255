import React from 'react';
import './App.css';
import Home from './Pages/Home'
import Services from './Pages/Services'
import Contact from './Pages/Contact'
import About from './Pages/About'
import Nav from './Components/Nav'
import { Routes, Route } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop';

function App() {
  return (
    <div className="App">
      <Nav className='sticky-nav'/>
      <ScrollToTop/>
      <Routes>
       <Route path='/' element={<Home/>}/>
       <Route path='/Services' element={<Services/>}/>
       <Route path='/About' element={<About/>}/>
       <Route path='/Contact' element={<Contact/>}/>
      </Routes>
    </div>
  );
}

export default App;
