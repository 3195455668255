import React, { useState } from 'react'
import { useRef } from "react";
import '../Styles/feedback.css'
import emailjs from 'emailjs-com';
import { Fade } from 'react-awesome-reveal';



function FeedBack() {

  const [formInputs, setFormInputs] = useState({
    name: '',
    email: '',
    tel: '',
    subject: '',
    message: ''
  })

  const handleInputs =(e)=> {

    setFormInputs (prev=> ({
      ...prev, 
      [e.target.name]: e.target.value
    }))
  }

  const [isLoading, setIsLoading] = useState(false)
  setTimeout(() => { setIsLoading(false); }, 2000)

  const form = useRef()
    const sendEmail = (e) => {
        setIsLoading(true);
        e.preventDefault();
    
        emailjs.sendForm('service_gj4ts8q', 'template_jdh1si6', form.current, '9TlozRpW5hc2eSp96')
          .then((result) => {
            alert("Message Sent")   

          }, (error) => {
            alert("Message NOT Sent")             
          });

          e.target.reset()
  }

  // const sendEmail = (e) => {
  //     setIsLoading(true);
  //     e.preventDefault(); // Prevent page refresh
  
  //     // Create template parameters
  //     const templateParams = {
  //     name: formInputs.name,
  //     email:formInputs.email,
  //     tel:formInputs.tel,
  //     subject:formInputs.subject,
  //     message:formInputs.message,
  //     };


  //     // Send email using EmailJS
  //     emailjs.send('service_b4tvqsm', 'template_x4iut3s', templateParams, 'hMKsvlmpbZfpK2Ajk')
  //       .then((response) => {
  //         console.log('Email sent successfully!', response.status, response.text);
  //         alert('Quote sent successfully!');
  //       }, (err) => {
  //         console.error('Failed to send email. Error:', err);
  //         alert('Failed to send email');
  //       })
  //       .finally(() => {
  //         setIsLoading(false); // End loading after request completes
  //     });
       
  // };

  return (
    <div className='feedback-container'>
   <div className="feedback-text">
        <h2>Request a Quote Today</h2>
        <h3>Reach Us</h3>
        <h4>Location:</h4>
        <p>13 Baale Street, Igbo-efon, Lagos, State, Nigeria</p>
        <h4>Email:</h4>
        <p>Info@pruudenttechnologies.com  info.pruudenttechnologies@aol.com</p>
        <h4>Tel:</h4>
        <p>+234 811 334 1983</p>

      </div>
      <div className="feedback-form">
        <form ref={form} onSubmit={sendEmail}>
            <input onChange={handleInputs} type="text" name='name' placeholder='Full Name' required/>
            <input onChange={handleInputs} type="email" name='email' placeholder='Email'required />
            <input onChange={handleInputs} type="number" name='tel' placeholder='Contact Number'required />
            <input onChange={handleInputs} type="text" name='subject' placeholder='Purpose of Contact'required />
            <textarea onChange={handleInputs}  rows="5" cols="30" name="message"  placeholder='Your Message'></textarea>
            <button className='submit-btn'> {isLoading ? "Submitting..." : "Reach Out"}</button>
        </form>
      </div>

      

       

    </div>
  )
}

export default FeedBack