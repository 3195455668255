import React from 'react'
import WhyBk from '../Assets/tech-backgroiund.jpg'
import '../Styles/whyUs.css'

function WhyUs() {
  return (
    <div className='why-us-container'>
        <div className="why-image">
            <img src={WhyBk} alt="tech-background-image" />
        </div>
        <div className="why-text">

            <div>
                <h2>Why Choose Us</h2>
                <h3>Expertise</h3>
                <p>Our team of experienced professionals brings deep expertise in the construction, energy and power industry</p>
            </div>
            <div>
                <h3>Customization</h3>
                <p>We understand that every business is unique. Therefore, we offer tailor-made solutions to align with your specific needs.</p>
            </div>
            <div>
                <h3>Quality</h3>
                <p>We are committed to delivering high-quality services and solutions that meet and exceed our clients' expectations.</p>
            </div>
            <div>
                <h3>Client Satisfaction</h3>
                <p>We prioritize client satisfaction and strive to build long-lasting partnerships based on trust and mutual success.</p>
            </div>
            
        </div>
        
    </div>
  )
}

export default WhyUs