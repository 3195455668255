import React from 'react'
import FeedBack from '../Components/FeedBack'
import Footer from '../Components/Footer'

function Contact() {
  return (
    <div>
        <FeedBack/>
        <Footer/>
    </div>
  )
}

export default Contact