import React from 'react'
import WhatWeDo from '../Components/WhatWeDo'
import WhyUs from '../Components/WhyUs'
import FeedBack from '../Components/FeedBack'
import Footer from '../Components/Footer'

function Services() {
  return (
    <div>
      <WhatWeDo/>
      <WhyUs/>
      <FeedBack/>
      <Footer/>
    </div>
  )
}

export default Services