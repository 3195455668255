import React from 'react'
import '../Styles/footer.css'
import Logo from '../Assets/Prudent logo.png'
import X from '../Assets/X-logo.svg'
import Facebook from '../Assets/facebook.svg'
import Instagram from '../Assets/instagram.svg'
import LinkedIn from '../Assets/linkedin.svg'
import { Link } from 'react-router-dom'

function Footer() {
  const getYear = new Date().getFullYear()
  return (
    <div className='footer-container'>
        <div className="footer-logo">      
            <Link to='/'>
             <img src={Logo} alt="pruudenttechnologies-logo" /></Link>
        </div>   
        <div className="useful-links">   
          <Link to='/'>Home</Link>
          <Link to='/About'>About Us</Link>
          <Link to='/Services'>Services</Link>       
          <Link to='/Contact'>Contact</Link>         
        </div>   
        <div className="social-icons">
          <a href="#"target='_blank' rel='noreferrer'><img src={X} alt="X-logo" /></a>
          <a href="#"target='_blank' rel='noreferrer'> <img className='facebook-icon' src={Facebook} alt="facebook-logo" /></a>
          <a href="#"target='_blank' rel='noreferrer'><img src={Instagram} alt="instagram-logo" /></a>            
          <a href="https://www.linkedin.com/company/105546422/admin/dashboard/"target='_blank' rel='noreferrer'><img src={LinkedIn} alt="instagram-logo" /></a>            
        </div>    

        <div className="copy-right">
          <p>Copyright &copy; {getYear} Pruudent Technologies</p>     
          <p>Powered by: pruudenttechnologies.com</p>
        </div>            
    

           
     
    </div>
        

  )
}

export default Footer