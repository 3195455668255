import React, { useState } from 'react'
import VisionIcon from '../Assets/vision.svg'
import MissionIcon from '../Assets/mission.svg'
import ValueIcon from '../Assets/value.svg'
import '../Styles/aboutComp.css'

function AboutComp() {

  const [activeTab, setActiveTab] = useState(1)
  const [showContent, setShowContent] = useState(false)

  const handleShow = (index)=> {
    setShowContent(true)
    setActiveTab(index)
  }

  console.log(activeTab, showContent);
  

  return (
    <div className='about-container'>
        <div className="about-text">
            <h3>About Us</h3>
            <p>At Pruudent Technologies, we believe that true innovation goes beyond technology—it’s about delivering meaningful solutions that improve lives, empower businesses, and shape the future.</p>
        </div>
        <div className="principles">
          <div className="tab">
            <div onClick={()=> handleShow(1)} className={activeTab === 1? 'vision active-vision':'vision'}>
            <img src={VisionIcon} alt="vision-icon" />
            <h4>Our Vision</h4>
            </div>
            <div onClick={()=> handleShow(2)} className={activeTab === 2 ? 'mission active-mission':'mission'}>
            <img src={MissionIcon} alt="mission-icon" />
            <h4>Our Mission</h4>
            </div>
            <div onClick={()=> handleShow(3)} className={activeTab === 3 ? 'values active-value':'values'}>
            <img src={ValueIcon} alt="value-icon" />
            <h4>Our Core Values</h4>
            </div>
         
         
        
          </div>
          <div className="tab-contents">
             {activeTab === 1 && showContent === true? <div className="vision-content">
              <p> Our Vision is to become a global reference in creating environments and spaces, where everyone and everything is safe and secured.</p>

             </div>: ''}
             {activeTab === 2 && showContent === true ? <div className="mission-content">
              <p> Our mission is to sustain continuous research on workplace safety, leverage data and technology to imagine possibilities; and engage the right people with the right attitude to develop bespoke solutions for all our clients whilst building value for stakeholders and preserving our world.</p>
             </div>: ''}
            { activeTab === 3 && showContent === true? <div className="value-content">
              <ul>
                <h3>Our Core Values include:</h3>
                <li> Innovation with Purpose</li>
                <li> Customer Success at the Core</li>
                <li> Integrity and Transparency</li>
                <li> Commitment to Quality and Excellence</li>
                <li>Empowering People through Technology</li>
              </ul>
             </div>:''}
          </div>
         
        </div>

    </div>
  )
}

export default AboutComp