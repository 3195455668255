import Uwaifo from '../Assets/uwaifo.jpg'
import Ima from '../Assets/ima.jpg'
import Amara from '../Assets/amara.jpg'
import Okechukwu from '../Assets/okechukwu.jpg'
import Leela from '../Assets/leela.jpg'


const ourTeam = [
{
    id: 1,
    name: 'Emmanuel Uwaifo',
    image: Uwaifo,
    position: 'Co-Founder, Chief Technology Officer & President',

},
{
    id: 2,
    name: 'Ima Akpan',
    image: Ima,
    position: 'Co-Founder, Chief Operations Officer',

},
{
    id: 4,
    name: 'Okechukwu Ukaegbu',
    image: Okechukwu,
    position: 'Co-Founder, Chief Product Officer',

},
{
    id: 3,
    name: 'Amara Grace Chima-Azubuike',
    image: Amara,
    position: 'Co-Founder, Chief Business Development & Customer Experience Officer',

},

{
    id: 5,
    name: 'Leela Firima',
    image: Leela,
    position: 'Co-Founder, Chief Innovation Officer',

},
]

export default ourTeam;