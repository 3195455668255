import React from 'react'
import TeamData from '../Data/TeamData.js'
import '../Styles/team.css'

function Team() {
  return (
    <div className='team-container'>
        <h2>Meet Our Team</h2>
        <p>At Pruudent Technologies, our strength lies in the dedication, expertise, and creativity of our talented team. We bring together individuals with diverse skills and experiences, united by a shared passion for excellence and innovation</p>
      <div className="team-card">
      { TeamData.map((item)=> (
        <div key={item.id} className="team-contents">
            <div className="team-image">
                <img src={item.image} alt="team-member-image" />
            </div>
            <div className="team-text">
                <h3>{item.name}</h3>
                <p>{item.position}</p>
    
            </div> 
        </div>
      
      ))  }
      </div>
    </div>
  )
}

export default Team