import CloudTech from '../Assets/cloud.jpg'
import Ai from '../Assets/ai.jpg'
import DataAnalytics from '../Assets/data-analytics.jpg'
import WirelessCamera from '../Assets/camera.jpg'


const whatWeDoData = [
    {
    id: 1,
    image: CloudTech,
    title: 'Unlock the Power of Cloud Technology',
    desc: 'Embrace the future of business with our comprehensive, secure, and scalable cloud technology services designed to empower your organization.'
},
{
    id: 2,
    image: Ai,
    title: 'Transform Your Business with Artificial Intelligence',
    desc: 'In an era defined by data and digital innovation, artificial intelligence (AI) stands at the forefront of transformative technology.'
},   
{
    id: 3,
    image: DataAnalytics,
    title: 'Unlock Insights and Drive Decisions with Data Analytics',
    desc: 'In today’s fast-paced digital world, data is more than numbers—it’s the foundation for informed decision-making and strategic growth.',
},  
{
    id: 4,
    image: WirelessCamera,
    title: 'Elevate Experiences with Wearables, Audio-Visual Technologies, and Intelligent Cameras',
    desc: 'Step into the future with a suite of advanced technologies designed to enhance connectivity, efficiency, and user experience.'
},


]


export default whatWeDoData;