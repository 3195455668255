import React from 'react'
import whatWeDoData from '../Data/WhatWeDoData'
import '../Styles/whatWeDo.css'
import { Fade } from 'react-awesome-reveal'

function WhatWeDo() {
  return (
    <div className='what-we-do-container'>
       <Fade direction='up'> <div className="what-we-do-text">
            <h2>What We Do</h2>
            <p>We provide tech-enabled safety solutions and services to several industries at about the best prices. Our major clients and prospects operate in the Oil & Gas sector, Supply Chain and Inventory Management Sector, Construction, Procurement and Logistics, Education, Health & Hospital Management, Hospitality and Retailing.</p>
        </div></Fade>
        <div className="what-card-container">         
       
        {whatWeDoData.map((item) => (<div className='what-we-do-card' key={item.id}>
           <Fade direction='left'><div className="what-we-do-card-image">
                <img src={item.image} alt="service-icon" />                        
             </div></Fade>
            <Fade direction='right'> <div className="what-we-do-card-text">
                <h3>{item.title}</h3>
                <p>{item.desc}</p>
             </div> </Fade>
        </div>   
        ) )} 
            
         
     
     
      </div>
    </div>
  )
}

export default WhatWeDo