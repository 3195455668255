import React from 'react'
import AboutComp from '../Components/AboutComp'
import Team from '../Components/Team'
import Footer from '../Components/Footer'

function About() {
  return (
    <div>
      <AboutComp/>
      <Team/>
      <Footer/>
    </div>
  )
}

export default About