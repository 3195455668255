import {React} from 'react'
import LandingPage from '../Components/LandingPage'
import WhatWeDo from '../Components/WhatWeDo'
import AboutComp from '../Components/AboutComp'
import WhyUs from '../Components/WhyUs'
import Team from '../Components/Team'
import FeedBack from '../Components/FeedBack'
import Footer from '../Components/Footer'


function Home() {

  return (
    <div>
      <LandingPage/>
      <WhatWeDo/>
      <AboutComp/>
      <WhyUs/>
      <Team/>
      <FeedBack/>
      <Footer/>
    </div>
  )
}

export default Home